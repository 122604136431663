<template>
<FormKitLazyProvider config-file="true" :default-config="false">
  <Dropdown
    :disabled="disabled"
    :open="isOpen"
    :button-text="triggerTitle"
    :button-theme="triggerTheme"
    @open="isOpen = true"
    @close="isOpen = false"
  >
    <template #default>
      <FormKit
        :id="formId"
        v-model="formData"
        type="form"
        :actions="false"
        :classes="{
          form: 'px-4 md:max-h-96',
        }"
        @submit="persistInUrl"
      >
        <FormKit
          type="radio"
          :name="URL_FILTER.DATES"
          :options="dates"
          :classes="{
            outer: '$reset w-full',
            inner: '!hidden',
            option: '$reset w-full',
            wrapper: '$reset w-full block data-[checked]:text-primary py-1',
            label: 'tabular-nums block',
          }"
          @input="setDate"
        />
      </FormKit>
    </template>

    <template #footer>
      <Button
        :theme="BUTTON_THEME.SECONDARY"
        :size="BUTTON_SIZE.SM"
        :text="t('btn.delete')"
        @click="resetForm"
      />
    </template>
  </Dropdown>
</FormKitLazyProvider>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import type { FilterOption } from '#gql/default'

interface Props {
  filterOption: FilterOption
  urlPrefix?: string
  preselected?: string
  disabled?: boolean
}

const props = defineProps<Props>()
const {
  formId,
  formData,
  urlValues,
  persistInUrl,
  setData,
  submitForm,
  resetForm,
  isOpen,
} = useFiltersForm({
  urlPrefix: props.urlPrefix,
  filterName: URL_FILTER.DATES,
  resetFilters: [URL_FILTER.PERIODS],
})

const { t, d } = useI18n()
const dates = computed(() =>
  props.filterOption.values?.map(({ key }) => ({
    label: getLabel(key),
    value: key,
  }))
)

function getLabel(date: string) {
  const formattedDate = d(new Date(date), {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })

  if (isToday(date)) {
    return `${t('label.today')}, ${formattedDate}`
  } else if (isTomorrow(date)) {
    return `${t('label.tomorrow')}, ${formattedDate}`
  }

  return d(new Date(date), {
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })
}

const currentDate = computed(() =>
  urlValues.value?.[0] ? getLabel(urlValues.value?.[0]) : null
)

if (props.preselected && !urlValues.value?.[0]) {
  setData(props.preselected)
}

async function setDate(date: string) {
  setData(date)
  await nextTick()
  submitForm()
}

const triggerTitle = computed(() => currentDate.value ?? t('btn.date'))
const triggerTheme = computed(() =>
  currentDate.value ? BUTTON_THEME.PRIMARY : BUTTON_THEME.SECONDARY
)

watch(currentDate, () => {
  setData(urlValues.value?.[0])
})

defineOptions({
  name: 'FiltersDates',
})
</script>

<i18n>
de:
  label:
    today: "Heute"
    tomorrow: "Morgen"
  btn:
    date: "Datum"
    delete: "Löschen"
es:
  label:
    today: "Hoy"
    tomorrow: "Mañana"
  btn:
    date: "Fecha"
    delete: "Borrar"
</i18n>
